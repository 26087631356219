@media(max-width:557px){
    .main_heading{
        font-size: 60px ;
    }
    .arror{
        width: 92px;
        height: 32px;
    }
   .gaps{
    gap: 20px;
   }
   .title_heading_1{
    font-size: 36px;
   }
   .plan{
    font-size: 16px;
   }
   .artist_para{
    font-size: 15px;
   }
   .get_start a{
    font-size: 18px;
   }
   .circle img{
    width: 50%;
   }
   .Curated{
    margin-bottom: 1rem;
   }
}

@media(max-width:768px){
.terms{
    font-size: 14px;
}

}
@media(max-width:991px){
    
}
@media(max-width:1200px){
    
}
@media(min-width:992px) and (max-width:1224px){
.artist_para{
    font-size: 18px;
}
}
@media(min-width:992px) and (max-width:1250px){
.main_heading{
    font-size: 90px;
}
.get_start a {
    font-size: 16px;
}
}