*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: sans-serif;
}

.w-90{
  width: 94% !important;
  margin: auto;
}
.w-85{
  width: 90% !important;
  margin: auto;
}
a{
  color: #000 !important;
}
.Curated{
  margin-bottom: 8rem;
}
.navbar {
  display: flex;
  justify-content: end !important;
  align-items: center;

  border-bottom: 1px solid rgba(0, 0, 0, 0.07);

}
nav.navbar {
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
  background: #fff;
}
.nav-links {
  font-size: 16px;
  list-style: none;
  display: flex;
  margin: 0px !important;
 

}

.nav-links li {

  padding: 10px 16px;
}

.nav-links li a {
  color: hsla(210, 26%, 7%, 0.7);;
  font-weight: 700;
  text-decoration: none;
}

.mobile-menu-icon {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #000;
}
button.mobile-menu-icon {
  font-size: 30px;
}
@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    list-style: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 20px 0px;
  }

  .nav-links-mobile li {
    text-align: center;
    margin-top: 1rem;
  }

  .nav-links-mobile li a {
    color: #fff !important;
    text-decoration: none;
  }

  .mobile-menu-icon {
    display: block;
  }
}
nav.navbar {
  padding-right: 20px;
}




/*================================= Banner section  start===========================*/

.main_heading{
  font-size: 132px;
  text-align: start;
  color: #191919;
  font-family: TT Ramillas Trl Medium;
}
.get_start{
  border: 1px solid #000;
  border-radius: 50px;
  padding: 8px 40px;
}
.get_start a{
  font-size: 28px;
  color: #191919;
}
.list_img img{
  max-width: 100%;
}
.logo{
  width: 38px;
  height: 38px;
}
.c_nmae{
  font-size: 28px;
  font-family: TT Ramillas Trl Medium;
}
.arror{
  width: 143px;
  height: 43px;
}
.gaps{
  gap: 82px;
}
.list_img{
  position: relative;

}
.lists{
  position: absolute;
  top: 6%;
  left:16%;
  color: #fff;
  font-size: 20px;
  font-weight: 700;

}
.title_heading_1{
  font-size: 85px;
  font-family: TT Ramillas Trl Medium;
}



/* ====================Create section start */

.plan{
  font-size: 24px;
}
.circle img{
  max-width: 100%;
}
.artist_para{
  font-size: 22px;
  color: #191919;
  margin: 0px;
  padding: 16px 0px;
}
.card_art{
  border: 1px solid #191919;
  border-radius: 50px;
  
}

/* pickoptions section start */
.arror_1{
  width: 55px;
  height: 180px;
  transform: rotate(-180deg);
}

/* .options_img img{

  height: 155px;
} */
/* .options_img_1 img{
  height: 264px;
} */

/* choose section start */
.lists_chose{
  position: absolute;
  left: 2%;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  bottom: 7%;

}



.list_img.pluse_icon {
  margin-left: 20px;
}
   /* chosse_3 section start          */
.pluse_icon{
  position: relative;
}
.plus{
  position: absolute;
  top: 50%;
  left: -40px;
  background-color: red;
  width: 30px;
  height: 30px;
  font-size: 20px;
}

/* ===================footer section start */
.footer{
  background-color: #000;
  padding: 20px 0px;
}

.terms{
  
  padding: 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.terms:hover{
  background-color: rgba(255, 255, 255, 0.15);
}
.terms_1{
  padding: 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.15);
}
.terms_1:hover{
  background-image: linear-gradient(to right, #4086c9, #489739);
   
}

